var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"modal"},[_c('b-form',{on:{"submit":_vm.onSubmit}},[(_vm.prop.slug == 'fincaraiz' && _vm.prop.state == 'active')?_c('b-row',{ref:"fincaraiz"},[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Email"}},[_c('b-form-input',{attrs:{"trim":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('b-form-invalid-feedback',{attrs:{"state":!(errors.length > 0)}},[_vm._v(" Debes registrar el Email de tu portal ")])],1)]}}],null,false,3866485801)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Contraseña"}},[_c('b-form-input',{attrs:{"trim":""},model:{value:(_vm.form.user),callback:function ($$v) {_vm.$set(_vm.form, "user", $$v)},expression:"form.user"}}),_c('b-form-invalid-feedback',{attrs:{"state":!(errors.length > 0)}},[_vm._v(" Debes registrar la contraseña de tu portal ")])],1)]}}],null,false,1051435152)})],1)],1):_vm._e(),(_vm.prop.slug == 'clasificados_el_pais' && _vm.prop.state == 'active')?_c('b-row',{ref:"clasificados-el-pais"},[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Codigo cliente(CustomerId)"}},[_c('b-form-input',{attrs:{"trim":""},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c('b-form-invalid-feedback',{attrs:{"state":!(errors.length > 0)}},[_vm._v(" Debes registrar el codigo del cliente de tu portal ")])],1)]}}],null,false,605185366)})],1)],1):_vm._e(),(_vm.prop.slug == 'metro_cuadrado' && _vm.prop.state == 'active')?_c('b-row',{ref:"metro-cuadrado"},[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Nombre de usuario"}},[_c('b-form-input',{attrs:{"trim":""},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c('b-form-invalid-feedback',{attrs:{"state":!(errors.length > 0)}},[_vm._v(" Debes registrar el Nombre de usuario de tu portal ")])],1)]}}],null,false,2054665107)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Contraseña"}},[_c('b-form-input',{attrs:{"trim":"","type":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-form-invalid-feedback',{attrs:{"state":!(errors.length > 0)}},[_vm._v(" Debes registrar la Contraseña de tu portal ")])],1)]}}],null,false,1562980943)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Token (Client ID)"}},[_c('b-form-input',{attrs:{"trim":""},model:{value:(_vm.form.client_id),callback:function ($$v) {_vm.$set(_vm.form, "client_id", $$v)},expression:"form.client_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":!(errors.length > 0)}},[_vm._v(" Debes registrar el Token de tu portal ")])],1)]}}],null,false,2908357659)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"X API KEY"}},[_c('b-form-input',{attrs:{"trim":""},model:{value:(_vm.form.xapikey),callback:function ($$v) {_vm.$set(_vm.form, "xapikey", $$v)},expression:"form.xapikey"}}),_c('b-form-invalid-feedback',{attrs:{"state":!(errors.length > 0)}},[_vm._v(" Debes registrar el X API KEY de tu portal ")])],1)]}}],null,false,1483392530)})],1)],1):_vm._e(),(_vm.prop.slug == 'ciencuadras' && _vm.prop.state == 'active')?_c('b-row',{ref:"ciencuadras"},[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Nombre de usuario"}},[_c('b-form-input',{attrs:{"trim":""},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c('b-form-invalid-feedback',{attrs:{"state":!(errors.length > 0)}},[_vm._v(" Debes registrar el Nombre de usuario de tu portal ")])],1)]}}],null,false,2054665107)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Contraseña"}},[_c('b-form-input',{attrs:{"type":"password","trim":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-form-invalid-feedback',{attrs:{"state":!(errors.length > 0)}},[_vm._v(" Debes registrar la Contraseña de tu portal ")])],1)]}}],null,false,639465231)})],1)],1):_vm._e(),(_vm.prop.slug == 'mercado_libre' && _vm.prop.state == 'active')?_c('b-row',{ref:"mercado_libre"},[_c('b-col',{attrs:{"ms":"12","lg":"6","xl":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Correo Electrónico"}},[_c('b-form-input',{attrs:{"trim":"","state":errors.length > 0 ? false : null},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)]}}],null,false,2344131010)})],1),_c('b-col',{attrs:{"ms":"12","lg":"6","xl":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Contraseña"}},[_c('b-form-input',{attrs:{"trim":"","state":errors.length > 0 ? false : null},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)]}}],null,false,3257295781)})],1),_c('b-col',{attrs:{"ms":"12","lg":"6","xl":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Cliente codigo(client_id)"}},[_c('b-form-input',{attrs:{"trim":"","state":errors.length},model:{value:(_vm.form.client_id),callback:function ($$v) {_vm.$set(_vm.form, "client_id", $$v)},expression:"form.client_id"}})],1)]}}],null,false,4181805257)})],1),_c('b-col',{attrs:{"ms":"12","lg":"6","xl":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Secreto del cliente(client_secret)"}},[_c('b-form-input',{attrs:{"trim":"","state":errors.length},model:{value:(_vm.form.client_secret),callback:function ($$v) {_vm.$set(_vm.form, "client_secret", $$v)},expression:"form.client_secret"}})],1)]}}],null,false,1713620308)})],1),_c('b-col',{attrs:{"ms":"12","lg":"6","xl":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Redirigir uri(redirect_uri)"}},[_c('b-form-input',{attrs:{"trim":"","state":errors.length},model:{value:(_vm.form.redirect_uri),callback:function ($$v) {_vm.$set(_vm.form, "redirect_uri", $$v)},expression:"form.redirect_uri"}})],1)]}}],null,false,1120748399)})],1),_c('b-col',{attrs:{"ms":"12","lg":"6","xl":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Codigo(code)"}},[_c('b-form-input',{attrs:{"trim":"","state":errors.length},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1)]}}],null,false,1831521397)})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }